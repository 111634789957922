<template>
  <page-header-wrapper :title="false">
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="标题">
                <a-input placeholder="请输入轮播图标题" v-model="queryParam.title" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="位置">
                <a-select
                  v-model="queryParam.type"
                  placeholder="请选择位置"
                  allow-clear
                >
                  <a-select-option :key="-1" :value="-1">请选择位置</a-select-option>
                  <a-select-option v-for="item in typeList" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="处理状态">
                <a-select
                  v-model="queryParam.status"
                  placeholder="请选择状态"
                  allow-clear
                >
                  <a-select-option :key="-1" :value="-1">请选择状态</a-select-option>
                  <a-select-option v-for="item in statusList" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="searchForm">查询</a-button>
                <a-button style="margin-left: 8px" @click="resetSearchForm()">重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="addRotationInfo()">添加</a-button>
      </div>
      <a-card :bordered="false">
        <a-spin :spinning="loading">
          <a-list size="large" :pagination="pagination" :dataSource="dataList">
            <a-list-item slot="renderItem" slot-scope="item" :key="item.id">
              <a-list-item-meta :description="item.descStr">
                <a slot="title" @click="showSeeInfo(item.id)">
                  {{ item.title }}
                  <a-tag color="gold" v-if="item.status === 1">{{ item.statusName }}</a-tag>
                  <a-tag color="green" v-else>{{ item.statusName }}</a-tag>
                </a>
              </a-list-item-meta>
              <div slot="actions">
                <a @click="handleRotationInfo(item)">编辑</a>
                <a-divider type="vertical" />
                <a-popconfirm title="确认要上架此轮播图吗？" @confirm="setRotationStatusApi(item.id,0)" v-if="item.status === 1">
                  <a>上架</a>
                </a-popconfirm>
                <a-popconfirm title="确认要下架此轮播图吗？" @confirm="setRotationStatusApi(item.id,1)" v-else>
                  <a>下架</a>
                </a-popconfirm>
                <a-divider type="vertical" />
                <a-popconfirm title="确认要删除此轮播图吗？" @confirm="delRotationApi(item.id)" :disabled="(item.status !== 1)">
                  <a :disabled="(item.status !== 1)">删除</a>
                </a-popconfirm>
              </div>
              <div class="list-content">
                <div class="list-content-item">
                  <a @click="handlePreview(item.imageUrl, item.title)">
                    <img :src="item.imageUrl" :height="50" />
                  </a>
                </div>
                <div class="list-content-item">
                  <span style="width:350px;text-align: left;">跳转地址</span>
                  <p style="width:350px;text-align: left; word-break:break-all;" v-if="item.url !== ''">{{ item.url }}</p>
                  <p style="width:350px;text-align: left;" v-else>--</p>
                </div>
                <div class="list-content-item">
                  <span style="text-align: left;">位置</span>
                  <p style="text-align: left;"><a-tag color="#108ee9">{{ item.typeName }}</a-tag></p>
                </div>
                <div class="list-content-item">
                  <span>创建时间</span>
                  <p>{{ item.createTime }}</p>
                </div>
              </div>
            </a-list-item>
          </a-list>
        </a-spin>
        <a-modal :visible="previewInfo.previewVisible" :title="previewInfo.previewTitle" :footer="null" @cancel="cancelPreview">
          <img alt="example" style="width: 100%" :src="previewInfo.previewImage" />
        </a-modal>
      </a-card>
      <rotation-info ref="rotationinfo" @refreshData="refreshData" :statusList="statusList" :typeList="typeList" @ok="handleOk"></rotation-info>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { delRotation, rotationCondition, rotationList, setRotationStatus } from '@/api/rotation'
import RotationInfo from '@/views/system/components/RotationInfo'

export default {
  name: 'RotationList',
  components: {
    RotationInfo
  },
  data () {
    return {
      eventDealData: {},
      loading: false,
      description: '',
      visible: false,
      form: this.$form.createForm(this, { name: 'addRotationFrom' }),
      mdl: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },

      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {
        title: '',
        type: -1,
        pageNo: 1,
        status: -1,
        pageSize: 10
      },
      dataList: [],
      pagination: {
        onChange: (page, pageSize) => this.onChange(page, pageSize),
        onShowSizeChange: (current, pageSize) => this.showSizeChange(current, pageSize),
        showTotal: (total) => `共 ${total} 条`,
        showSizeChanger: true,
        pageSize: 10,
        total: 0,
        defaultCurrent: 1,
        current: 1
      },
      statusList: [],
      typeList: [],
      previewInfo: {
        previewVisible: false,
        previewTitle: '',
        previewImage: ''
      }
    }
  },
  filters: {
  },
  created () {
    this.rotationConditionApi()
    this.rotationListApi()
  },
  methods: {
    rotationListApi () {
      const _this = this
      _this.loading = true
      rotationList(_this.queryParam).then((res) => {
        if (res.errorCode === 0) {
          _this.dataList = res.result.data
          _this.pagination.pageSize = res.result.pageSize
          _this.pagination.total = res.result.totalCount
          _this.pagination.current = res.result.pageNo
          _this.loading = false
          console.log('pagination', _this.pagination)
        }
      }).catch((err) => {
        _this.loading = false
        console.log(err)
      })
    },
    rotationConditionApi () {
      const _this = this
      rotationCondition().then((res) => {
        if (res.errorCode === 0) {
          _this.statusList = res.result.statusList
          _this.typeList = res.result.typeList
          console.log('rotationCondition', res)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    delRotationApi (rotationId) {
      const _this = this
      delRotation({ rotationId: rotationId }).then((res) => {
        if (res.errorCode === 0) {
          _this.$message.success('操作成功')
          _this.rotationListApi()
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    setRotationStatusApi (rotationId, status) {
      const _this = this
      setRotationStatus({ rotationId: rotationId, status: status }).then((res) => {
        if (res.errorCode === 0) {
          _this.$message.success('操作成功')
          _this.rotationListApi()
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    handlePreview (url, name) {
      this.previewInfo.previewImage = url
      this.previewInfo.previewTitle = name
      this.previewInfo.previewVisible = true
    },
    cancelPreview (e) {
      console.log(e)
      this.previewInfo.previewVisible = false
      this.previewInfo.previewImage = ''
      this.previewInfo.previewTitle = ''
    },
    searchForm () {
      this.queryParam.pageNo = 1
      this.rotationListApi()
    },
    resetSearchForm () {
      this.queryParam = {
        title: '',
        status: -1,
        type: -1,
        pageNo: 1,
        pageSize: 10
      }
    },
    onChange (page, pageSize) {
      this.queryParam.pageNo = page
      this.queryParam.pageSize = pageSize
      this.rotationListApi()
    },
    showSeeInfo (keyId) {
    },
    refreshData (e) {
      console.log(e)
      this.rotationListApi()
    },
    handleRotationInfo (data) {
      this.$refs.rotationinfo.initialData(data)
    },
    addRotationInfo () {
      const info = {
        type: 1,
        status: 1,
        attachmentsList: [],
        startTime: '',
        endTime: '',
        id: 0,
        imageUrl: '',
        title: '',
        url: ''
      }
      this.$refs.rotationinfo.initialData(info)
    },
    handleCancel (e) {
      this.eventDealData = {}
      console.log('handleCancel', e)
      this.visible = false
    },
    handleOk () {
      this.visible = false
      this.rotationListApi()
    },
    showSizeChange (current, pageSize) {
      this.pagination.current = 1
      this.pagination.defaultCurrent = 1
      this.pagination.pageSize = pageSize
      this.queryParam.pageNo = 1
      this.queryParam.pageSize = pageSize
      this.rotationListApi()
    }
  },
  watch: {
  }
}
</script>
<style lang="less" scoped>
.ant-avatar-lg {
  width: 48px;
  height: 48px;
  line-height: 48px;
}

.list-content-item {
  color: rgba(0, 0, 0, .45);
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  margin-left: 20px;
  span {
    line-height: 20px;
  }
  p {
    margin-top: 4px;
    margin-bottom: 0;
    line-height: 22px;
  }
}
</style>
